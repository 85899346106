/*-----------------------------------*\
 * #style.css
\*-----------------------------------*/

/**
 * copyright 2024 Sinclair Retreats
 */





/*-----------------------------------*\
 * #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
   * colors
   */

  --united-nations-blue: hsl(214, 56%, 58%);
  --bright-navy-blue: hsl(214, 57%, 51%);
  --spanish-gray: hsl(0, 0%, 60%);
  --black-coral: hsl(225, 8%, 42%);
  --oxford-blue: hsl(208, 97%, 12%);
  --yale-blue: hsl(214, 72%, 33%);
  --blue-ncs: hsl(197, 100%, 36%);
  --gunmetal: hsl(206, 34%, 20%);
  --gunmetal-hover: hsl(206, 34%, 30%);
  --gainsboro: hsl(0, 0%, 88%);
  --cultured: hsl(0, 0%, 98%);
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
  --onyx: hsl(0, 0%, 25%);
  --jet: hsl(0, 0%, 20%);

  /**
   * typography
   */

  --ff-poppins: "Poppins", sans-serif;
  --ff-montserrat: "Montserrat", sans-serif;

  --fs-1: calc(20px + 3.5vw);
  --fs-2: calc(18px + 1.6vw);
  --fs-3: calc(16px + 0.45vw);
  --fs-4: 15px;
  --fs-5: 14px;
  --fs-6: 13px;
  --fs-7: 12px;
  --fs-8: 11px;

  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  /**
   * transition
   */

  --transition: 0.25s ease-in-out;

  /**
   * spacing
   */

  --section-padding: 60px;

  /**
   * border-radius
   */

  --radius-15: 15px;
  --radius-25: 25px;

}

.bg-body-tertiary {
  background-color: var(--gunmetal) !important;
}



/*-----------------------------------*\
 * #RESET
\*-----------------------------------*/

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a { text-decoration: none; }

a,
img,
span,
input,
label,
button,
ion-icon { display: block; }

input,
button {
  background: none;
  border: none;
  font: inherit;
}

button { cursor: pointer; }

input { width: 100%; }

ion-icon { pointer-events: none; }

html {
  font-family: var(--ff-poppins);
  scroll-behavior: smooth;
}

body { background: var(--white); }





/*-----------------------------------*\
 * #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 15px; }

.btn {
  color: var(--white);
  text-transform: uppercase;
  font-size: var(--fs-5);
  border-radius: 100px;
  padding: var(--padding, 8px 18px);
  border: var(--border-width, 2px) solid transparent;
  transition: var(--transition);
}

.btn-primary {
  background: var(--bright-navy-blue);
  border-color: var(--bright-navy-blue);
}

.btn-primary:is(:hover, :focus) {
  background: var(--yale-blue);
  border-color: var(--yale-blue);
}

.btn-secondary { border-color: var(--white); }

.btn-secondary:is(:hover, :focus) { background: hsla(0, 0%, 100%, 0.1); }

.h1,
.h2,
.h3 {
  font-weight: var(--fw-800);
  font-family: var(--ff-montserrat);
  text-transform: uppercase;
}

.h1 {
  color: var(--white);
  font-size: var(--fs-1);
}

.h2,
.h3 { color: var(--gunmetal); }

.h2 { font-size: var(--fs-2); }

.h3 {
  font-size: var(--fs-3);
  font-weight: var(--fw-700);
}

.section-subtitle {
  color: var(--gunmetal);
  font-size: var(--fs-3);
  text-transform: uppercase;
  font-family: var(--ff-montserrat);
  margin-bottom: 8px;
}

.section-title { 
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
 }

.section-text {
  color: var(--black-coral);
  margin-bottom: 30px;
}

.card-text {
  color: var(--black-coral);
  font-size: var(--fs-5);
}


.accordion-button:focus {
  box-shadow: none !important;
}


/*-----------------------------------*\
 * #HEADER
\*-----------------------------------*/

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 61px;
  z-index: 4;
  transition: background-color 0.3s ease; /* Add smooth transition for background color */
}

.header-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: var(--transition);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  padding-block: 15px;
  z-index: 1;
  background-color: var(--gunmetal);
}

.header.sticky .header-top { /* Make sure header gets background for both active and sticky states */
  position: fixed;
  background: var(--gunmetal); /* Background color when scrolled or sticky */
}

.header.active .header-top {
  display: none !important; 
}

/* Active logo appears when scrolled or in sticky mode */
.active-logo {
  display: none;
}

.header.active .active-logo,
.header.sticky .active-logo { /* Display logo when header is active or sticky */
  display: block;
  position: fixed;
  left: 60px;
}

.header.active .active-logo img,
.header.sticky .active-logo img { /* Make sure the logo appears correctly */
  max-height: 30px;
}

.header-top .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: flex-start;
  align-items: center;
}

.helpline-box .wrapper { display: none; }

.helpline-box .icon-box {
  background: var(--bright-navy-blue);
  padding: 6px;
  border-radius: 50%;
  color: var(--white);
}

.helpline-box .icon-box ion-icon { --ionicon-stroke-width: 40px; }

.header-top .logo { margin-inline: auto; }

.header-top .logo img {
   max-width: 100px;
   max-height: 60px;
}

.header-btn-group {
  justify-self: flex-end;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--white);
}

.search-btn,
.nav-open-btn {
  font-size: 30px;
  color: inherit;
}

.search-btn { font-size: 20px; }

.header-bottom { border-bottom: 1px solid hsla(0, 0%, 100%, 0.1); }

.header-bottom .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-block: 15px;
}

.social-list {
  display: flex;
  align-items: center;
  gap: 5px;
}

.social-link {
  color: var(--white);
  padding: 8px;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
  border-radius: 50%;
  font-size: 15px;
  transition: var(--transition);
}

.social-link:is(:hover, :focus) { background: hsla(0, 0%, 100%, 0.2); }

.header .btn { --padding: 4px 20px; }

.header .navbar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 100%;
  max-width: 300px;
  height: 100%;
  background: var(--white);
  visibility: hidden;
  pointer-events: none;
  transition: 0.15s ease-in;
  z-index: 3;
}

.navbar.active {
  right: 0;
  visibility: visible;
  pointer-events: all;
  transition: 0.25s ease-out;
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 15px;
}

.navbar-top .logo img { width: 150px; }

.nav-close-btn {
  font-size: 20px;
  color: var(--bright-navy-blue);
}

.nav-close-btn ion-icon { --ionicon-stroke-width: 80px; }

.navbar-list { border-top: 1px solid hsla(0, 0%, 0%, 0.1); }

.navbar-list li { border-bottom: 1px solid hsla(0, 0%, 0%, 0.1); }

.navbar-link {
  padding: 15px 20px;
  color: var(--white);
  font-weight: var(--fw-500);
  font-size: var(--fs-4);
  transition: var(--transition);
  text-transform: capitalize;
}

.navbar-link:is(:hover, :focus) { color: var(--bright-navy-blue); }

.overlay {
  position: fixed;
  inset: 0;
  background: var(--black);
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  transition: var(--transition);
}

.overlay.active {
  opacity: 0.7;
  pointer-events: all;
}

/* Add specific styles for sticky header on non-landing pages */
.header.sticky {
  position: fixed;
  top: 0;
  background-color: var(--gunmetal); /* Header background color on About page (sticky) */
  z-index: 10;
  width: 100%;
  padding-top: 0 !important; /* Remove top padding to align content properly */
}

.header.sticky .header-top {
  background-color: var(--gunmetal);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

/* Ensure smooth transition between active and sticky states */
.header.active, .header.sticky {
  background-color: var(--gunmetal);
}

.header.active {
  padding-top: 0px;
}


/* .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 61px;
  z-index: 4;
}

.header-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: var(--transition);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
  padding-block: 15px;
  z-index: 1;
}

.header.active .header-top {
  position: fixed;
  background: var(--gunmetal);
}

.active-logo {
  display: none;
}

.header.active .active-logo {
  display: block;
  position: fixed;
  left: 60px;
}

.header.active .active-logo img {
  max-height: 30px;
}

.header-top .container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: flex-start;
  align-items: center;
}

.helpline-box .wrapper { display: none; }

.helpline-box .icon-box {
  background: var(--bright-navy-blue);
  padding: 6px;
  border-radius: 50%;
  color: var(--white);
}

.helpline-box .icon-box ion-icon { --ionicon-stroke-width: 40px; }

.header-top .logo { margin-inline: auto; }

.header-top .logo img {
   max-width: 100px;
   max-height: 60px;
}

.header-btn-group {
  justify-self: flex-end;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--white);
}

.search-btn,
.nav-open-btn {
  font-size: 30px;
  color: inherit;
}

.search-btn { font-size: 20px; }

.header-bottom { border-bottom: 1px solid hsla(0, 0%, 100%, 0.1); }

.header-bottom .container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-block: 15px;
}

.social-list {
  display: flex;
  align-items: center;
  gap: 5px;
}

.social-link {
  color: var(--white);
  padding: 8px;
  border: 1px solid hsla(0, 0%, 100%, 0.3);
  border-radius: 50%;
  font-size: 15px;
  transition: var(--transition);
}

.social-link:is(:hover, :focus) { background: hsla(0, 0%, 100%, 0.2); }

.header .btn { --padding: 4px 20px; }

.header .navbar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 100%;
  max-width: 300px;
  height: 100%;
  background: var(--white);
  visibility: hidden;
  pointer-events: none;
  transition: 0.15s ease-in;
  z-index: 3;
}

.navbar.active {
  right: 0;
  visibility: visible;
  pointer-events: all;
  transition: 0.25s ease-out;
}

.navbar-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 15px;
}

.navbar-top .logo img { width: 150px; }

.nav-close-btn {
  font-size: 20px;
  color: var(--bright-navy-blue);
}

.nav-close-btn ion-icon { --ionicon-stroke-width: 80px; }

.navbar-list { border-top: 1px solid hsla(0, 0%, 0%, 0.1); }

.navbar-list li { border-bottom: 1px solid hsla(0, 0%, 0%, 0.1); }

.navbar-link {
  padding: 15px 20px;
  color: var(--jet);
  font-weight: var(--fw-500);
  font-size: var(--fs-4);
  transition: var(--transition);
  text-transform: capitalize;
}

.navbar-link:is(:hover, :focus) { color: var(--bright-navy-blue); }

.overlay {
  position: fixed;
  inset: 0;
  background: var(--black);
  opacity: 0;
  pointer-events: none;
  z-index: 2;
  transition: var(--transition);
}

.overlay.active {
  opacity: 0.7;
  pointer-events: all;
} */


/*-----------------------------------*\
 * #DoctorGrid
\*-----------------------------------*/
.doctor-photo {
  width: 250px;   /* Reduce the size slightly */
  height: 250px;  /* Keep the image square */
  object-fit: cover;  /* Ensures the image covers the container without distortion */
}

.doctor-photo-wrapper {
  flex-shrink: 0; /* Prevents the photo from shrinking */
}

.doctor-row {
  gap: 20px; /* Reduce the gap between image and text */
}

@media (min-width: 768px) {
  .doctor-row {
    max-width: 900px; /* Limit the width of the entire row */
    margin: 0 auto; /* Center the rows */
  }
}

@media (max-width: 767.98px) {
  .doctor-row {
    flex-direction: column !important; /* Stack images and text on small screens */
    text-align: center;
  }
  .doctor-info {
    margin-top: 15px;
  }
}

/*-----------------------------------*\
 * #HERO
\*-----------------------------------*/

.hero {
  background-image: url("../public/assets/images/hero-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: hsla(0, 0%, 0%, 0.6);
  background-blend-mode: overlay;
  display: grid;
  place-items: center;
  min-height: 600px;
  text-align: center;
  padding-top: 125px;
}

/*
.doctor-hero {
  background-image: url("../public/assets/images/doctor_grid_banner.jpg");
  background-position: center -300px; // Moves the image 50px up
  background-size: cover; // Ensures the image covers the entire element
  background-repeat: no-repeat; // Prevents the image from repeating
} 
*/


.doctor-hero {
  background-image: url("../public/assets/images/doctor_grid_banner.jpg"); /* Check path */
  background-position: center -375px; /* Default for larger screens */
  background-size: cover;
  background-repeat: no-repeat;
  height: 250px; /* Default height */
}

/* Media query for mobile screens */
@media (max-width: 1114px) {
  .doctor-hero {
    background-position: center; /* Adjust position for mobile */
    height: 250px; /* Adjust height for smaller screens */
  }
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .doctor-hero {
    background-position: center; /* Adjust position for mobile */
    height: 150px; /* Adjust height for smaller screens */
  }
}

.hero-title { 
  margin-bottom: 20px;
  font-size: var(--fs-2);
 }

.hero-text {
  color: var(--white);
  font-size: var(--fs-5);
  margin-bottom: 40px;
}

.btn-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.btn-group img {
  max-height: 30px;
}

.btn-group span {
  color: var(--white);
  text-transform: uppercase;
}


.hero .container {
  margin-top: auto;
}


/*-----------------------------------*\
 * #TOUR SEARCH
\*-----------------------------------*/

.tour-search {
  background: var(--bright-navy-blue);
  padding-block: var(--section-padding);
}

.tour-search-form .input-label {
  color: var(--white);
  font-size: var(--fs-4);
  margin-left: 20px;
  margin-bottom: 10px;
}

.tour-search-form .input-field {
  background: var(--white);
  padding: 10px 15px;
  font-size: var(--fs-5);
  border-radius: 50px;
}

.tour-search-form .input-field::placeholder { color: var(--spanish-gray); }

.tour-search-form .input-field::-webkit-datetime-edit {
  color: var(--spanish-gray);
  text-transform: uppercase;
}

.tour-search-form .input-wrapper { margin-bottom: 15px; }

.tour-search .btn {
  width: 100%;
  --border-width: 1px;
  font-weight: var(--fw-600);
  margin-top: 35px;
}





/*-----------------------------------*\
 * #POPULAR
\*-----------------------------------*/

.popular { 
  padding-block: var(--section-padding);
  /* background-color: #f8f9fa; */
 }

.popular-list,
.popular-list > li:not(:last-child) { margin-bottom: 30px; }

.popular-card {
  position: relative;
  overflow: hidden;
  border-radius: var(--radius-25);
  height: 430px;
}

.popular-card .card-img { height: 100%; }

.popular-card .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popular-card .card-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background: var(--white);
  border-radius: var(--radius-25);
  padding: 20px;
}


.popular-card .card-rating {
  background: var(--bright-navy-blue);
  color: var(--white);
  position: absolute;
  top: 0;
  right: 25px;
  display: flex;
  align-items: center;
  gap: 1px;
  transform: translateY(-50%);
  padding: 6px 10px;
  border-radius: 20px;
  font-size: 14px;
}

.popular-card .card-subtitle {
  color: var(--blue-ncs);
  font-size: var(--fs-6);
  text-transform: uppercase;
  margin-bottom: 8px;
}

.popular-card .card-title { margin-bottom: 5px; }

.popular-card :is(.card-subtitle, .card-title) > a { color: inherit; }

.popular .btn { margin-inline: auto; }

/* General column styling */
.max-column {
  max-height: 700px; /* Max height for columns */
  height: 100%; /* Full height */
}

/* Staggered effect for 1st and 3rd columns */
.staggered {
  margin-top: 100px; /* Create staggered look */
}

/* Add spacing between image and text */
.max-column img {
  max-height: 40%; /* Control image height */
  width: auto; /* Maintain aspect ratio */
  margin-bottom: 100px;
}

/* Specific margins for images */
.max-column img.mb-4 {
  margin-bottom: 1.5rem; /* Increase bottom margin */
}

.max-column img.mt-5 {
  margin-top: 80px; /* Increase space above the image */
}

/* Spacing control for paragraph text */
.max-column p {
  overflow: hidden; /* Prevent text overflow */
  text-overflow: ellipsis; /* Ellipsis for overflowing text */
}

.max-column .text-center {
  padding-top: 20px; /* Add padding between image and text */
  padding-bottom: 20px; /* Add padding between text and column end */
}

/*-----------------------------------*\
 * #FAQ
\*-----------------------------------*/

.faq-section {
  margin-bottom: 1.75rem !important;
}

/*-----------------------------------*\
 * #BESPOKE
\*-----------------------------------*/

.bespoke-section {
  background-color: #f8f9fa;
  padding-block: var(--section-padding);
}

.bespoke-section h2 {
  font-size: 2rem; /* Adjust heading size as needed */
  color: #333; /* Dark text color */
}

.bespoke-section p {
  font-size: 1rem; /* Adjust paragraph font size */
  line-height: 1.6; /* Improve readability with line height */
  color: #555; /* Slightly lighter text color for paragraphs */
}

/*-----------------------------------*\
 * #WAVE BACKGROUND
\*-----------------------------------*/

.wave-background {
  position: relative;
  width: 100%; /* Full width of the container */
  height: 150px; /* Adjust height as needed */
  overflow: hidden;
  background-color: #ffffff; /* Background color to blend with the wave */
}

.wave-background-gray {
  position: relative;
  width: 100%; /* Full width of the container */
  height: 150px; /* Adjust height as needed */
  overflow: hidden;
  background-color: #f8f9fa; /* Background color to blend with the wave */
}

.wave-background svg {
  display: block;
  width: 100%;
  height: 100%;
}

.wave-background-gray svg {
  display: block;
  width: 100%;
  height: 100%;
}

/*-----------------------------------*\
 * #PACKAGE
\*-----------------------------------*/

.package { padding-block: var(--section-padding); }

.package-list { margin-bottom: 40px; }

.package-list > li:not(:last-child) { margin-bottom: 30px; }

.package-card {
  background: var(--cultured);
  overflow: hidden;
  border-radius: 15px;
}

.package-card .card-banner { height: 250px; }

.package-card .card-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.package-card .card-content { padding: 30px 20px; }

.package-card .card-title { margin-bottom: 15px; }

.package-card .card-text {
  line-height: 1.6;
  margin-bottom: 20px;
}

.card-meta-list {
  background: var(--white);
  max-width: max-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.15);
  border-radius: 50px;
}

.card-meta-item { position: relative; }

.card-meta-item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 4px;
  right: -1px;
  bottom: 4px;
  width: 1px;
  background: hsla(0, 0%, 0%, 0.3);
}

.meta-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding-inline: 9px;
  color: var(--black-coral);
  font-size: var(--fs-8);
}

.meta-box > ion-icon {
  color: var(--bright-navy-blue);
  font-size: 13px;
}

.package-card .card-price {
  background: var(--united-nations-blue);
  color: var(--white);
  padding: 25px 20px;
  text-align: center;
}

.package-card .card-price .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px 15px;
  margin-bottom: 10px;
}

.package-card .card-price .reviews { font-size: var(--fs-5); }

.package-card .card-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
  font-size: 14px;
}

.package-card .card-rating ion-icon:last-child { color: hsl(0, 0%, 80%); }

.package-card .price {
  font-size: var(--fs-2);
  font-family: var(--ff-montserrat);
  font-weight: var(--fw-800);
  margin-bottom: 20px;
}

.package-card .price span {
  font-size: var(--fs-7);
  font-weight: initial;
}

.package .btn { margin-inline: auto; }



/*-----------------------------------*\
 * #GALLERY
\*-----------------------------------*/

.gallery { 
  padding-block: var(--section-padding);
  background-color: #f8f9fa; 
}

.gallery-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding-left: 0px;
}

.gallery-image {
  width: 100%;
  height: 100%;
  border-radius: var(--radius-15);
  overflow: hidden;
}

.gallery-item:nth-child(3) { grid-area: 1 / 2 / 3 / 3; }

.gallery-image a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-image a {
  display: inline;
}

/*-----------------------------------*\
 * #MISSION
\*-----------------------------------*/


.mission-section {
  background-color: #f8f9fa; /* Light background color for contrast */
  padding-block: var(--section-padding); /* Section padding */
}

.mission-section .container {
  max-width: 900px; /* Restrict the maximum width of the text */
}

.mission-text {
  font-size: 1rem; /* Slightly reduce font size for compactness */
  line-height: 1.6; /* Adjust line height for better readability */
  margin-bottom: 1.5rem; /* Spacing between paragraphs */
  color: #333; /* Dark text color for readability */
}

.mission-section h2 {
  font-size: 2rem; /* Adjust heading size */
  font-weight: bold; /* Make the heading stand out */
  margin-bottom: 20px; /* Space below the heading */
  color: #333; /* Heading text color */
}


/*-----------------------------------*\
 * #CTA
\*-----------------------------------*/

/* Contact Form Styling */
.contact-form-section {
  background-color: #e3f2fd; /* Light background for contrast */
  padding: 50px 0; /* Padding for section */
}

.form-heading {
  font-size: 1.75rem; /* Heading size */
  font-weight: bold;
  color: #343a40; /* Dark text color */
}

.form-control {
  border-radius: 0.25rem; /* Slightly rounded corners for inputs */
  border: 1px solid #ced4da; /* Border color */
  padding: 10px; /* Padding inside inputs */
}

.form-control:focus {
  border-color: #007bff; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.25); /* Slight shadow on focus */
}

.btn-primary {
  background-color: #007bff; /* Primary button color */
  border: none;
  padding: 12px; /* Button padding */
  font-size: 1rem;
  font-weight: bold;
  border-radius: 0.25rem; /* Button corners */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.btn-primary:hover {
  background-color: #0056b3; /* Darker blue on hover */
}






/*-----------------------------------*\
 * #FOOTER
\*-----------------------------------*/

.footer-top {
  background: var(--gunmetal);
  padding-block: var(--section-padding);
  color: var(--gainsboro);
}

.footer-brand { margin-bottom: 30px; }

.footer-brand img { width: 180px; }

.footer-brand .logo { margin-bottom: 20px; }

.footer-text {
  font-size: var(--fs-5);
  line-height: 1.7;
}

.footer-contact { margin-bottom: 30px; }

.contact-title {
  position: relative;
  font-family: var(--ff-montserrat);
  font-weight: var(--fw-500);
  margin-bottom: 30px;
}

.contact-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 50px;
  height: 2px;
  background: var(--bright-navy-blue);
}

.contact-text {
  font-size: var(--fs-5);
  margin-bottom: 15px;
  max-width: 200px;
}

.contact-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.contact-item ion-icon { --ionicon-stroke-width: 40px; }

.contact-link,
address {
  font-style: normal;
  color: var(--gainsboro);
  font-size: var(--fs-5);
}

.contact-link:is(:hover, :focus) { color: var(--white); }

.form-text {
  font-size: var(--fs-5);
  margin-bottom: 20px;
}

.footer-form .input-field {
  background: var(--white);
  font-size: var(--fs-5);
  padding: 15px 20px;
  border-radius: 100px;
  margin-bottom: 10px;
}

.footer-form .btn { width: 100%; }

.footer-bottom {
  --gunmetal: hsl(205, 36%, 17%);
  background: var(--gunmetal);
  padding-block: 20px;
  text-align: center;
}

.copyright {
  color: var(--gainsboro);
  font-size: 12px;
  margin-bottom: 10px;
}

.copyright a {
  color: inherit;
  display: inline-block;
}

.copyright a:is(:hover, :focus) { color: var(--white); }

.footer-bottom-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 21px;
  margin-bottom: 0 !important;
}

.footer-bottom-list > li { position: relative; }

.footer-bottom-list > li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 3px;
  right: -10px;
  bottom: 3px;
  width: 1px;
  background: hsla(0, 0%, 100%, 0.2);
}

.footer-bottom-link {
  color: var(--gainsboro);
  font-size: 12px;
  transition: var(--transition);
}

.footer-bottom-link:is(:hover, :focus) { color: var(--white); }





/*-----------------------------------*\
 * #GO TO TOP
\*-----------------------------------*/

.go-top {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 35px;
  height: 35px;
  background: var(--gunmetal);
  color: var(--white);
  display: grid;
  place-items: center;
  font-size: 18px;
  border-radius: 6px;
  box-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.5);
  opacity: 0;
  transform: translateY(10px);
  visibility: hidden;
  transition: var(--transition);
}

.go-top.active {
  opacity: 0.8;
  transform: translateY(0);
  visibility: visible;
}

.go-top:is(:hover, :focus) { opacity: 1; }


/**
   * GALLERY
   */

   .learn-more-btn {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: #fff;
    background-color: var(--gunmetal); /* Primary color that contrasts well */
    border-radius: 5px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .learn-more-btn:hover {
    background-color: var(--gunmetal-hover); /* Slightly darker shade for hover */
  }

  .learn-more-btn:disabled {
    background-color: var(--gunmetal);
    opacity: 0.6; /* Slightly transparent to indicate it's disabled */
    cursor: not-allowed; /* Show "not-allowed" cursor */
  }



/*-----------------------------------*\
 * #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 580px screen
 */

@media (min-width: 580px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 580px;
    margin-inline: auto;
  }

  .btn {
    --fs-5: 16px;
    --padding: 12px 30px;
  }

  section:not(.cta) :is(.section-subtitle, .section-title, .section-text) {
    text-align: center;
  }

  .section-text { margin-bottom: 40px; }

  .card-text { --fs-5: 15px; }



  /**
   * HEADER
   */

  .header { padding-top: 83px; }

  .helpline-box .icon-box { padding: 14px; }

  .header-top .logo img { max-width: unset; }

  .search-btn { font-size: 30px; }

  .nav-open-btn { font-size: 40px; }

  .header .btn {
    --fs-5: 14px;
    --padding: 6px 20px;
  }



  /**
   * HERO
   */

  .hero {
    min-height: 800px;
    padding-top: 85px;
  }

  .hero-text { --fs-5: 15px; }

  .btn-group { gap: 20px; }



  /**
   * TOUR SEARCH
   */

  .tour-search-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
  }

  .tour-search-form .input-wrapper { margin-bottom: 0; }

  .tour-search-form .input-field { padding: 16px 20px; }

  .tour-search .btn {
    grid-column: span 2;
    margin-top: 20px;
  }



  /**
   * POPULAR
   */

  .popular-card .card-content { right: auto; }



  /**
   * FOOTER
   */

  .footer .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }

  .footer-form { grid-column: span 2; }

  .footer-bottom { text-align: left; }

  .copyright { margin-bottom: 0; }

  .footer-bottom-list { justify-content: flex-end; }

}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-5: 15px;
  }


  /**
   * REUSED STYLE
   */

  .container { max-width: 800px; }

  .section-text {
    max-width: 60ch;
    margin-inline: auto;
  }



  /**
   * HEADER
   */

  .helpline-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .helpline-box .wrapper {
    display: block;
    color: var(--white);
    font-size: var(--fs-6);
  }

  .social-list { gap: 10px; }



  /**
   * POPULAR
   */

  .popular-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 50px;
  }

  .popular-list > li:not(:last-child) { margin-bottom: 0; }

  .popular-card .card-content { right: 20px; }



  /**
   * PACKAGE
   */

  .package-list { margin-bottom: 50px; }

  .package-list > li:not(:last-child) { margin-bottom: 40px; }

  .package-card {
    display: grid;
    grid-template-columns: 1.3fr 1.5fr 1fr;
  }

  .package-card .card-banner { height: 100%; }

  .package-card .card-content { padding: 40px; }

  .package-card .card-price {
    display: grid;
    place-content: center;
  }

  .package-card .card-price .wrapper { margin-bottom: 15px; }

  

  .gallery { padding-bottom: calc(var(--section-padding * 2)); }

  .gallery-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .gallery-image { border-radius: var(--radius-25); }



  /**
   * CTA
   */

  .cta .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cta-content { width: calc(100% - 225px); }

  .cta .section-text { margin-inline: 0; }



  /**
   * FOOTER
   */

  .form-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .footer-form .input-field { margin-bottom: 0; }

  .footer-form .btn { width: max-content; }

}


/**
 * responsive for larger than 992px screen
 */

@media (min-width: 992px) {

  /**
   * REUSED STYLE
   */

  .container { max-width: 1050px; }



  /**
   * HEADER
   */

  .header.active .header-top {
    position: unset;
    background: unset;
  }

  .nav-open-btn,
  .navbar-top { display: none; }

  .header-bottom { border-bottom: none; }

  .header.active .header-bottom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--gunmetal);
    color: var(--white);
    transition: var(--transition);
  }

  .header-bottom .container { padding-block: 0; }

  .header .navbar { all: unset; }

  .navbar-list {
    border-top: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-list li { border-bottom: none; }

  .navbar-link {
    color: var(--white);
    --fs-4: 16px;
    font-weight: unset;
    text-transform: uppercase;
    padding: 20px 15px;
  }

  .header.active .navbar-link { color: var(--white); }

  .header.active .navbar-link:is(:hover, :focus) { color: var(--bright-navy-blue); }

  .header.active .social-list {
    display: none;
  }

  .header.active .social-link {
    color: var(--white);
    border-color: var(--white)
  }

  .overlay { display: none; }



  /**
   * HERO
   */

  .hero .container { max-width: 740px; }



  /**
   * TOUR SEARCH
   */

  .tour-search-form { grid-template-columns: repeat(5, 1fr); }

  .tour-search .btn {
    --padding: 15px;
    grid-column: unset;
    margin-top: 0;
  }



  /**
   * POPULAR
   */

  .popular-list { grid-template-columns: repeat(3, 1fr); }



  /**
   * PACKAGE
   */

  .meta-box { --fs-8: 13px; }

  .meta-box > ion-icon { font-size: 15px; }



  /**
   * CTA 
   */

  .cta .section-title { max-width: 25ch; }



  /**
   * FOOTER
   */

  .footer-top .container {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
  }

  .footer-form { grid-column: unset; }

  .form-wrapper { flex-direction: column; }

  .footer-form .btn { width: 100%; }

}





/**
 * responsive for larger than 1200px screen
 */

@media (min-width: 1200px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * spacing
     */

    --section-padding: 100px;

  }



  /**
   * REUSED STYLE
   */

  .container { max-width: 1180px; }

}


/* TOS */
.terms-and-conditions {
  @import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

  ol.lst-kix_list_3-2 { list-style-type: none; }
  ol.lst-kix_list_3-3 { list-style-type: none; }
  ol.lst-kix_list_3-4.start { counter-reset: lst-ctn-kix_list_3-4 0; }
  ol.lst-kix_list_3-4 { list-style-type: none; }
  .lst-kix_list_2-1>li { counter-increment: lst-ctn-kix_list_2-1; }
  ol.lst-kix_list_3-0 { list-style-type: none; }
  .lst-kix_list_1-1>li { counter-increment: lst-ctn-kix_list_1-1; }
  ol.lst-kix_list_2-6.start { counter-reset: lst-ctn-kix_list_2-6 0; }
  .lst-kix_list_3-0>li:before { content: "" counter(lst-ctn-kix_list_3-0, decimal) ". "; }
  .lst-kix_list_3-1>li:before { content: "o  "; }
  .lst-kix_list_3-2>li:before { content: "" counter(lst-ctn-kix_list_3-2, lower-roman) ". "; }
  ol.lst-kix_list_1-8.start { counter-reset: lst-ctn-kix_list_1-8 0; }
  ol.lst-kix_list_2-3.start { counter-reset: lst-ctn-kix_list_2-3 0; }
  .lst-kix_list_3-5>li:before { content: "" counter(lst-ctn-kix_list_3-5, decimal) ". "; }
  .lst-kix_list_3-4>li:before { content: "" counter(lst-ctn-kix_list_3-4, decimal) ". "; }
  ol.lst-kix_list_1-5.start { counter-reset: lst-ctn-kix_list_1-5 0; }
  .lst-kix_list_3-3>li:before { content: "" counter(lst-ctn-kix_list_3-3, decimal) ". "; }
  ol.lst-kix_list_3-5 { list-style-type: none; }
  ol.lst-kix_list_3-6 { list-style-type: none; }
  ol.lst-kix_list_3-7 { list-style-type: none; }
  ol.lst-kix_list_3-8 { list-style-type: none; }
  .lst-kix_list_3-8>li:before { content: "" counter(lst-ctn-kix_list_3-8, decimal) ". "; }
  .lst-kix_list_2-0>li { counter-increment: lst-ctn-kix_list_2-0; }
  .lst-kix_list_2-3>li { counter-increment: lst-ctn-kix_list_2-3; }
  .lst-kix_list_3-6>li:before { content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "; }
  .lst-kix_list_3-7>li:before { content: "" counter(lst-ctn-kix_list_3-7, decimal) ". "; }
  .lst-kix_list_1-2>li { counter-increment: lst-ctn-kix_list_1-2; }
  ol.lst-kix_list_3-7.start { counter-reset: lst-ctn-kix_list_3-7 0; }
  .lst-kix_list_3-2>li { counter-increment: lst-ctn-kix_list_3-2; }
  ol.lst-kix_list_2-2 { list-style-type: none; }
  ol.lst-kix_list_2-3 { list-style-type: none; }
  ol.lst-kix_list_2-4 { list-style-type: none; }
  ol.lst-kix_list_2-5 { list-style-type: none; }
  .lst-kix_list_1-4>li { counter-increment: lst-ctn-kix_list_1-4; }
  ol.lst-kix_list_2-0 { list-style-type: none; }
  ol.lst-kix_list_1-6.start { counter-reset: lst-ctn-kix_list_1-6 0; }
  ol.lst-kix_list_2-1 { list-style-type: none; }
  .lst-kix_list_4-8>li:before { content: "\0025aa   "; }
  .lst-kix_list_4-7>li:before { content: "\0025aa   "; }
  ul.lst-kix_list_4-8 { list-style-type: none; }
  ul.lst-kix_list_4-6 { list-style-type: none; }
  ul.lst-kix_list_4-7 { list-style-type: none; }
  ul.lst-kix_list_4-0 { list-style-type: none; }
  ul.lst-kix_list_4-1 { list-style-type: none; }
  ol.lst-kix_list_3-3.start { counter-reset: lst-ctn-kix_list_3-3 0; }
  ul.lst-kix_list_4-4 { list-style-type: none; }
  ol.lst-kix_list_2-6 { list-style-type: none; }
  ul.lst-kix_list_4-5 { list-style-type: none; }
  ol.lst-kix_list_2-7 { list-style-type: none; }
  ul.lst-kix_list_4-2 { list-style-type: none; }
  ol.lst-kix_list_2-8 { list-style-type: none; }
  ul.lst-kix_list_4-3 { list-style-type: none; }
  ol.lst-kix_list_1-0.start { counter-reset: lst-ctn-kix_list_1-0 0; }
  .lst-kix_list_3-0>li { counter-increment: lst-ctn-kix_list_3-0; }
  .lst-kix_list_3-3>li { counter-increment: lst-ctn-kix_list_3-3; }
  .lst-kix_list_3-6>li { counter-increment: lst-ctn-kix_list_3-6; }
  li.li-bullet-2:before { margin-left: -9pt; white-space: nowrap; display: inline-block; min-width: 9pt; }
  .lst-kix_list_2-5>li { counter-increment: lst-ctn-kix_list_2-5; }
  .lst-kix_list_2-8>li { counter-increment: lst-ctn-kix_list_2-8; }
  ol.lst-kix_list_3-2.start { counter-reset: lst-ctn-kix_list_3-2 0; }
  .lst-kix_list_2-2>li { counter-increment: lst-ctn-kix_list_2-2; }
  ol.lst-kix_list_2-4.start { counter-reset: lst-ctn-kix_list_2-4 0; }
  ol.lst-kix_list_1-3 { list-style-type: none; }
  ol.lst-kix_list_1-4 { list-style-type: none; }
  .lst-kix_list_2-6>li:before { content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "; }
  .lst-kix_list_2-7>li:before { content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "; }
  .lst-kix_list_2-7>li { counter-increment: lst-ctn-kix_list_2-7; }
  .lst-kix_list_3-7>li { counter-increment: lst-ctn-kix_list_3-7; }
  ol.lst-kix_list_1-5 { list-style-type: none; }
  ol.lst-kix_list_1-6 { list-style-type: none; }
  ol.lst-kix_list_1-0 { list-style-type: none; }
  .lst-kix_list_2-4>li:before { content: "" counter(lst-ctn-kix_list_2-4, lower-latin) ". "; }
  .lst-kix_list_2-5>li:before { content: "" counter(lst-ctn-kix_list_2-5, lower-roman) ". "; }
  .lst-kix_list_2-8>li:before { content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "; }
  ol.lst-kix_list_1-1 { list-style-type: none; }
  ol.lst-kix_list_1-2 { list-style-type: none; }
  ol.lst-kix_list_3-0.start { counter-reset: lst-ctn-kix_list_3-0 0; }
  ul.lst-kix_list_3-1 { list-style-type: none; }
  ol.lst-kix_list_1-7 { list-style-type: none; }
  .lst-kix_list_1-7>li { counter-increment: lst-ctn-kix_list_1-7; }
  ol.lst-kix_list_1-8 { list-style-type: none; }
  ol.lst-kix_list_3-8.start { counter-reset: lst-ctn-kix_list_3-8 0; }
  ol.lst-kix_list_2-5.start { counter-reset: lst-ctn-kix_list_2-5 0; }
  li.li-bullet-1:before { margin-left: -9pt; white-space: nowrap; display: inline-block; min-width: 9pt; }
  .lst-kix_list_4-0>li:before { content: "\0025cf   "; }
  .lst-kix_list_2-6>li { counter-increment: lst-ctn-kix_list_2-6; }
  .lst-kix_list_3-8>li { counter-increment: lst-ctn-kix_list_3-8; }
  .lst-kix_list_4-1>li:before { content: "o  "; }
  ol.lst-kix_list_1-7.start { counter-reset: lst-ctn-kix_list_1-7 0; }
  .lst-kix_list_4-4>li:before { content: "\0025aa   "; }
  ol.lst-kix_list_2-2.start { counter-reset: lst-ctn-kix_list_2-2 0; }
  .lst-kix_list_1-5>li { counter-increment: lst-ctn-kix_list_1-5; }
  .lst-kix_list_4-3>li:before { content: "\0025aa   "; }
  .lst-kix_list_4-5>li:before { content: "\0025aa   "; }
  .lst-kix_list_4-2>li:before { content: "\0025aa   "; }
  .lst-kix_list_4-6>li:before { content: "\0025aa   "; }
  .lst-kix_list_1-8>li { counter-increment: lst-ctn-kix_list_1-8; }
  ol.lst-kix_list_1-4.start { counter-reset: lst-ctn-kix_list_1-4 0; }
  .lst-kix_list_3-5>li { counter-increment: lst-ctn-kix_list_3-5; }
  ol.lst-kix_list_1-1.start { counter-reset: lst-ctn-kix_list_1-1 0; }
  .lst-kix_list_3-4>li { counter-increment: lst-ctn-kix_list_3-4; }
  .lst-kix_list_2-4>li { counter-increment: lst-ctn-kix_list_2-4; }
  ol.lst-kix_list_3-6.start { counter-reset: lst-ctn-kix_list_3-6 0; }
  ol.lst-kix_list_1-3.start { counter-reset: lst-ctn-kix_list_1-3 0; }
  ol.lst-kix_list_2-8.start { counter-reset: lst-ctn-kix_list_2-8 0; }
  ol.lst-kix_list_1-2.start { counter-reset: lst-ctn-kix_list_1-2 0; }
  .lst-kix_list_1-0>li:before { content: "" counter(lst-ctn-kix_list_1-0, decimal) ". "; }
  .lst-kix_list_1-1>li:before { content: "" counter(lst-ctn-kix_list_1-1, decimal) ". "; }
  .lst-kix_list_1-2>li:before { content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ". "; }
  ol.lst-kix_list_2-0.start { counter-reset: lst-ctn-kix_list_2-0 0; }
  .lst-kix_list_1-3>li:before { content: "" counter(lst-ctn-kix_list_1-3, decimal) ". "; }
  .lst-kix_list_1-4>li:before { content: "" counter(lst-ctn-kix_list_1-4, decimal) ". "; }
  ol.lst-kix_list_3-5.start { counter-reset: lst-ctn-kix_list_3-5 0; }
  .lst-kix_list_1-0>li { counter-increment: lst-ctn-kix_list_1-0; }
  .lst-kix_list_1-6>li { counter-increment: lst-ctn-kix_list_1-6; }
  .lst-kix_list_1-7>li:before { content: "" counter(lst-ctn-kix_list_1-7, decimal) ". "; }
  ol.lst-kix_list_2-7.start { counter-reset: lst-ctn-kix_list_2-7 0; }
  .lst-kix_list_1-3>li { counter-increment: lst-ctn-kix_list_1-3; }
  .lst-kix_list_1-5>li:before { content: "" counter(lst-ctn-kix_list_1-5, decimal) ". "; }
  .lst-kix_list_1-6>li:before { content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "; }
  li.li-bullet-0:before { margin-left: -18pt; white-space: nowrap; display: inline-block; min-width: 18pt; }
  .lst-kix_list_2-0>li:before { content: "" counter(lst-ctn-kix_list_2-0, decimal) ". "; }
  .lst-kix_list_2-1>li:before { content: "" counter(lst-ctn-kix_list_2-1, lower-latin) ". "; }
  ol.lst-kix_list_2-1.start { counter-reset: lst-ctn-kix_list_2-1 0; }
  .lst-kix_list_1-8>li:before { content: "" counter(lst-ctn-kix_list_1-8, decimal) ". "; }
  .lst-kix_list_2-2>li:before { content: "" counter(lst-ctn-kix_list_2-2, lower-roman) ". "; }
  .lst-kix_list_2-3>li:before { content: "" counter(lst-ctn-kix_list_2-3, decimal) ". "; }
  ol { margin: 0; padding: 0; }
  table td, table th { padding: 0; }
  .c11 { background-color: #ffffff; margin-left: 36pt; padding-top: 14pt; padding-left: 0pt; padding-bottom: 0pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c14 { background-color: #ffffff; margin-left: 36pt; padding-top: 0pt; padding-left: 0pt; padding-bottom: 0pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c15 { background-color: #ffffff; margin-left: 36pt; padding-top: 0pt; padding-left: 0pt; padding-bottom: 14pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c7 { -webkit-text-decoration-skip: none; color: #000000; font-weight: 400; text-decoration: underline; vertical-align: baseline; text-decoration-skip-ink: none; font-size: 12pt; font-family: "Times New Roman"; font-style: normal; }
  .c13 { margin-left: 18pt; padding-top: 14pt; padding-left: 0pt; padding-bottom: 11.2pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c5 { background-color: #ffffff; margin-left: 18pt; padding-top: 14pt; padding-bottom: 14pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c23 { margin-left: 99pt; padding-top: 0pt; padding-left: -9pt; padding-bottom: 11.2pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c22 { margin-left: 36pt; padding-top: 14pt; padding-left: 0pt; padding-bottom: 11.2pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c18 { margin-left: 72pt; padding-top: 14pt; padding-left: 0pt; padding-bottom: 11.2pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c24 { background-color: #ffffff; margin-left: 36pt; padding-top: 14pt; padding-bottom: 14pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c28 { margin-left: 36pt; padding-top: 0pt; padding-left: 0pt; padding-bottom: 11.2pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c4 { margin-left: 72pt; padding-top: 0pt; padding-left: 0pt; padding-bottom: 11.2pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c3 { margin-left: 18pt; padding-top: 0pt; padding-left: 0pt; padding-bottom: 11.2pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c6 { padding-top: 0pt; padding-bottom: 0pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; height: 12pt; }
  .c0 { color: #000000; font-weight: 400; text-decoration: none; vertical-align: baseline; font-size: 12pt; font-family: "Times New Roman"; font-style: normal; }
  .c30 { margin-left: 18pt; padding-top: 14pt; padding-bottom: 11.2pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c26 { padding-top: 0pt; padding-bottom: 0pt; line-height: 1.0; orphans: 2; widows: 2; text-align: right; }
  .c25 { padding-top: 0pt; padding-bottom: 15pt; line-height: 1.0; orphans: 2; widows: 2; text-align: right; }
  .c9 { padding-top: 0pt; padding-bottom: 0pt; line-height: 1.0; orphans: 2; widows: 2; text-align: center; }
  .c29 { padding-top: 0pt; padding-bottom: 15pt; line-height: 1.0; orphans: 2; widows: 2; text-align: justify; }
  .c8 { text-decoration: none; vertical-align: baseline; font-size: 12pt; font-style: normal; }
  .c21 { background-color: #ffffff; max-width: 468pt; padding: 72pt 72pt 72pt 72pt; }
  .c2 { font-family: "Times New Roman"; color: #000000; font-weight: 700; }
  .c10 { -webkit-text-decoration-skip: none; text-decoration: underline; text-decoration-skip-ink: none; }
  .c16 { vertical-align: baseline; font-size: 12pt; font-style: normal; }
  .c12 { font-weight: 400; font-family: "Times New Roman"; }
  .c1 { padding: 0; margin: 0; }
  .c19 { font-weight: 700; font-family: "Times New Roman"; }
  .c27 { vertical-align: super; }
  .c20 { color: #000000; }
  .c17 { height: 12pt; }
  .title { padding-top: 24pt; color: #000000; font-weight: 700; font-size: 36pt; padding-bottom: 6pt; font-family: "Calibri"; line-height: 1.0; page-break-after: avoid; orphans: 2; widows: 2; text-align: left; }
  .subtitle { padding-top: 18pt; color: #666666; font-size: 24pt; padding-bottom: 4pt; font-family: "Georgia"; line-height: 1.0; page-break-after: avoid; font-style: italic; orphans: 2; widows: 2; text-align: left; }
  li { color: #000000; font-size: 12pt; font-family: "Calibri"; }
  p { margin: 0; color: #000000; font-size: 12pt; font-family: "Calibri"; }
  span { display: inline; }
  h1 { padding-top: 0pt; color: #000000; font-weight: 700; font-size: 24pt; padding-bottom: 0pt; font-family: "Times New Roman"; line-height: 1.0; orphans: 2; widows: 2; text-align: left; }
  h2 { padding-top: 18pt; color: #000000; font-weight: 700; font-size: 18pt; padding-bottom: 4pt; font-family: "Calibri"; line-height: 1.0; page-break-after: avoid; orphans: 2; widows: 2; text-align: left; }
  h3 { padding-top: 14pt; color: #000000; font-weight: 700; font-size: 14pt; padding-bottom: 4pt; font-family: "Calibri"; line-height: 1.0; page-break-after: avoid; orphans: 2; widows: 2; text-align: left; }
  h4 { padding-top: 12pt; color: #000000; font-weight: 700; font-size: 12pt; padding-bottom: 2pt; font-family: "Calibri"; line-height: 1.0; page-break-after: avoid; orphans: 2; widows: 2; text-align: left; }
  h5 { padding-top: 11pt; color: #000000; font-weight: 700; font-size: 11pt; padding-bottom: 2pt; font-family: "Calibri"; line-height: 1.0; page-break-after: avoid; orphans: 2; widows: 2; text-align: left; }
  h6 { padding-top: 10pt; color: #000000; font-weight: 700; font-size: 10pt; padding-bottom: 2pt; font-family: "Calibri"; line-height: 1.0; page-break-after: avoid; orphans: 2; widows: 2; text-align: left; }
}

.privacy-policy {
  @import url(https://themes.googleusercontent.com/fonts/css?kit=1BVhQjsg_ddXeTZ1N5YwRA);

  .lst-kix_list_2-6>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_2-7>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_1-0 {
      list-style-type: none
  }

  .lst-kix_list_2-4>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_2-5>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_2-8>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_3-0>li:before {
      content: "\0025cf   "
  }

  ul.lst-kix_list_5-7 {
      list-style-type: none
  }

  ul.lst-kix_list_5-8 {
      list-style-type: none
  }

  .lst-kix_list_3-1>li:before {
      content: "o  "
  }

  .lst-kix_list_3-2>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_5-5 {
      list-style-type: none
  }

  ul.lst-kix_list_3-7 {
      list-style-type: none
  }

  ul.lst-kix_list_5-6 {
      list-style-type: none
  }

  ul.lst-kix_list_3-8 {
      list-style-type: none
  }

  ul.lst-kix_list_1-3 {
      list-style-type: none
  }

  ul.lst-kix_list_3-1 {
      list-style-type: none
  }

  .lst-kix_list_3-5>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_5-0 {
      list-style-type: none
  }

  ul.lst-kix_list_1-4 {
      list-style-type: none
  }

  ul.lst-kix_list_3-2 {
      list-style-type: none
  }

  ul.lst-kix_list_1-1 {
      list-style-type: none
  }

  .lst-kix_list_3-4>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_1-2 {
      list-style-type: none
  }

  ul.lst-kix_list_3-0 {
      list-style-type: none
  }

  ul.lst-kix_list_5-3 {
      list-style-type: none
  }

  ul.lst-kix_list_1-7 {
      list-style-type: none
  }

  .lst-kix_list_3-3>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_3-5 {
      list-style-type: none
  }

  ul.lst-kix_list_5-4 {
      list-style-type: none
  }

  ul.lst-kix_list_1-8 {
      list-style-type: none
  }

  ul.lst-kix_list_3-6 {
      list-style-type: none
  }

  ul.lst-kix_list_5-1 {
      list-style-type: none
  }

  ul.lst-kix_list_1-5 {
      list-style-type: none
  }

  ul.lst-kix_list_3-3 {
      list-style-type: none
  }

  ul.lst-kix_list_5-2 {
      list-style-type: none
  }

  ul.lst-kix_list_1-6 {
      list-style-type: none
  }

  ul.lst-kix_list_3-4 {
      list-style-type: none
  }

  .lst-kix_list_3-8>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_4-0>li:before {
      content: "\0025cf   "
  }

  .lst-kix_list_4-1>li:before {
      content: "o  "
  }

  .lst-kix_list_3-6>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_3-7>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_4-4>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_4-3>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_4-5>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_4-2>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_4-6>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_5-0>li:before {
      content: "\0025cf   "
  }

  .lst-kix_list_4-8>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_5-3>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_4-7>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_5-2>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_5-1>li:before {
      content: "o  "
  }

  ul.lst-kix_list_4-8 {
      list-style-type: none
  }

  .lst-kix_list_5-7>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_4-6 {
      list-style-type: none
  }

  .lst-kix_list_5-6>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_5-8>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_2-8 {
      list-style-type: none
  }

  ul.lst-kix_list_4-7 {
      list-style-type: none
  }

  ul.lst-kix_list_4-0 {
      list-style-type: none
  }

  ul.lst-kix_list_2-2 {
      list-style-type: none
  }

  ul.lst-kix_list_4-1 {
      list-style-type: none
  }

  .lst-kix_list_1-0>li:before {
      content: "\0025cf   "
  }

  ul.lst-kix_list_2-3 {
      list-style-type: none
  }

  .lst-kix_list_5-4>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_2-0 {
      list-style-type: none
  }

  ul.lst-kix_list_2-1 {
      list-style-type: none
  }

  ul.lst-kix_list_4-4 {
      list-style-type: none
  }

  .lst-kix_list_5-5>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_2-6 {
      list-style-type: none
  }

  ul.lst-kix_list_4-5 {
      list-style-type: none
  }

  .lst-kix_list_1-1>li:before {
      content: "o  "
  }

  .lst-kix_list_1-2>li:before {
      content: "\0025aa   "
  }

  ul.lst-kix_list_2-7 {
      list-style-type: none
  }

  ul.lst-kix_list_4-2 {
      list-style-type: none
  }

  ul.lst-kix_list_2-4 {
      list-style-type: none
  }

  ul.lst-kix_list_4-3 {
      list-style-type: none
  }

  ul.lst-kix_list_2-5 {
      list-style-type: none
  }

  .lst-kix_list_1-3>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_1-4>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_1-7>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_1-5>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_1-6>li:before {
      content: "\0025aa   "
  }

  li.li-bullet-0:before {
      margin-left: -18pt;
      white-space: nowrap;
      display: inline-block;
      min-width: 18pt
  }

  .lst-kix_list_2-0>li:before {
      content: "\0025cf   "
  }

  .lst-kix_list_2-1>li:before {
      content: "o  "
  }

  .lst-kix_list_1-8>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_2-2>li:before {
      content: "\0025aa   "
  }

  .lst-kix_list_2-3>li:before {
      content: "\0025aa   "
  }

  ol {
      margin: 0;
      padding: 0
  }

  table td,
  table th {
      padding: 0
  }

  .c7 {
      margin-left: 36pt;
      padding-top: 0pt;
      padding-left: 0pt;
      padding-bottom: 14pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  .c10 {
      margin-left: 36pt;
      padding-top: 14pt;
      padding-left: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  .c6 {
      margin-left: 36pt;
      padding-top: 0pt;
      padding-left: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  .c3 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 12pt;
      font-family: "Aptos";
      font-style: normal
  }

  .c0 {
      color: #000000;
      font-weight: 400;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 12pt;
      font-family: "Times New Roman";
      font-style: normal
  }

  .c14 {
      padding-top: 0pt;
      padding-bottom: 0pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left;
      height: 12pt
  }

  .c4 {
      padding-top: 0pt;
      padding-bottom: 15pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: right
  }

  .c2 {
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: center
  }

  .c5 {
      padding-top: 14pt;
      padding-bottom: 14pt;
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  .c8 {
      color: #000000;
      text-decoration: none;
      vertical-align: baseline;
      font-size: 12pt;
      font-style: normal
  }

  .c11 {
      font-family: "Times New Roman";
      color: #000000;
      font-weight: 400
  }

  .c9 {
      background-color: #ffffff;
      max-width: 468pt;
      padding: 72pt 72pt 72pt 72pt
  }

  .c1 {
      font-weight: 700;
      font-family: "Times New Roman"
  }

  .c12 {
      padding: 0;
      margin: 0
  }

  .c13 {
      vertical-align: super
  }

  .title {
      padding-top: 0pt;
      color: #000000;
      font-size: 28pt;
      padding-bottom: 4pt;
      font-family: "Play";
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  .subtitle {
      padding-top: 0pt;
      color: #595959;
      font-size: 14pt;
      padding-bottom: 8pt;
      font-family: "Aptos";
      line-height: 1.0;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  li {
      color: #000000;
      font-size: 12pt;
      font-family: "Aptos"
  }

  p {
      margin: 0;
      color: #000000;
      font-size: 12pt;
      font-family: "Aptos"
  }

  span { 
    display: inline; 
  }

  h1 {
      padding-top: 18pt;
      color: #0f4761;
      font-size: 20pt;
      padding-bottom: 4pt;
      font-family: "Play";
      line-height: 1.0;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  h2 {
      padding-top: 8pt;
      color: #0f4761;
      font-size: 16pt;
      padding-bottom: 4pt;
      font-family: "Play";
      line-height: 1.0;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  h3 {
      padding-top: 8pt;
      color: #0f4761;
      font-size: 14pt;
      padding-bottom: 4pt;
      font-family: "Aptos";
      line-height: 1.0;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  h4 {
      padding-top: 4pt;
      color: #0f4761;
      font-size: 12pt;
      padding-bottom: 2pt;
      font-family: "Aptos";
      line-height: 1.0;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  h5 {
      padding-top: 4pt;
      color: #0f4761;
      font-size: 12pt;
      padding-bottom: 2pt;
      font-family: "Aptos";
      line-height: 1.0;
      page-break-after: avoid;
      orphans: 2;
      widows: 2;
      text-align: left
  }

  h6 {
      padding-top: 2pt;
      color: #595959;
      font-size: 12pt;
      padding-bottom: 0pt;
      font-family: "Aptos";
      line-height: 1.0;
      page-break-after: avoid;
      font-style: italic;
      orphans: 2;
      widows: 2;
      text-align: left
  }
}